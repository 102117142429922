import React, { useState } from "react";
import {
  FaUser,
  FaPhone,
  FaMapMarkerAlt,
  FaCapsules,
  FaUserMd,
  FaWind,
  FaRegCommentAlt,
  FaLaughBeam,
  FaBirthdayCake,
  FaChild,
  FaInstagramSquare,
  FaFacebook,
} from "react-icons/fa";
import { motion } from "framer-motion";
import axios from "axios";
import img2 from "./IMG/1.jpeg";
import img1 from "./IMG/3.jpeg";

const App = () => {
  const [formData, setFormData] = useState({
    nombreCompleto: "",
    fechaNacimiento: "",
    edad: 0,
    numeroCelular: "",
    facebook: "",
    instagram: "",
    domicilio: "",
  });
  const [formData2, setFormData2] = useState({
    nombreTutor: "",
    numeroTutor: "",
    parentesco: "",
    alergias: "",
    medicinas: "",
    enfermedadCronica: "",
    abono: "",
    nota: "Sin Nota",
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const [isFirstFormVisible, setIsFirstFormVisible] = useState(true);

  const handleNextClick = () => {
    setIsFirstFormVisible(false);
  };

  // const handlePreviousClick = () => {
  //   setIsFirstFormVisible(true);
  // };

  const handleChange2 = (e) => {
    const { name, value } = e.target;
    setFormData2({ ...formData2, [name]: value });
    validateField2(name, value);
  };
  const validateField2 = (name, value) => {
    let error = "";
    switch (name) {
      case "nombreTutor":
        error = value.trim() === "" ? "El nombre completo es requerido" : "";
        break;
      case "parentesco":
        error = value.trim() === "" ? "Escribe algun parentesco" : "";
        break;

      case "numeroTutor":
        error = !/^\d{10}$/.test(value)
          ? "El número de celular debe tener 10 dígitos"
          : "";
        break;
      case "alergias":
        error = value.trim() === "" ? "Puedes escribir que no tienes" : "";
        break;
      case "medicinas":
        error = value.trim() === "" ? "Puedes escribir que no necesitas" : "";
        break;
      case "enfermedadCronica":
        error = value.trim() === "" ? "Puedes escribir que no tienes" : "";
        break;
      case "abono":
        error = value.trim() === "" ? "Describe sobre tu abono" : "";
        break;
      case "nota":
        error = value.trim() === "" ? "Escribe algo como nota" : "";
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };
  const handleSubmit2 = async (e) => {
    e.preventDefault();

    // Crear un objeto para recolectar los errores
    const formErrors = {};

    // Validar cada campo y recolectar errores
    Object.keys(formData2).forEach((key) => {
      const value = formData2[key];
      let error = "";
      switch (key) {
        case "nombreTutor":
          error = value.trim() === "" ? "El nombre completo es requerido" : "";
          break;
        case "parentesco":
          error = value.trim() === "" ? "Escribe algun parentesco" : "";
          break;

        case "numeroTutor":
          error = !/^\d{10}$/.test(value)
            ? "El número de celular debe tener 10 dígitos"
            : "";
          break;
        case "alergias":
          error = value.trim() === "" ? "Puedes escribir que no tienes" : "";
          break;
        case "medicinas":
          error = value.trim() === "" ? "Puedes escribir que no necesitas" : "";
          break;
        case "enfermedadCronica":
          error = value.trim() === "" ? "Puedes escribir que no tienes" : "";
          break;
        case "abono":
          error = value.trim() === "" ? "Describe sobre tu abono" : "";
          break;
        case "nota":
          error = value.trim() === "" ? "Escribe algo como nota" : "";
          break;
        default:
          break;
      }
      // Si hay un error, agregarlo al objeto de errores
      if (error) {
        formErrors[key] = error;
      }
    });

    // Si no hay errores, avanzar al siguiente formulario
    if (Object.keys(formErrors).length === 0) {
      setIsSubmitting(true);
      // Combinar los objetos formData y formData2
      const combinedData = { ...formData, ...formData2 };
      console.log("line 130");
      console.log(combinedData);
      try {
        // Simulando la llamada a la API
        const response = await axios.post(
          "https://hilarious-merola-canchesky-d538ace7.koyeb.app/users/",
          combinedData
        );

        setIsSubmitting(false);
        setIsSuccess(true);
        alert("Te registraste con exito");
        window.location.reload();

        console.log("Datos enviados con éxito:", response.data);
      } catch (error) {
        console.error("Error al enviar los datos:", error);
        setIsSubmitting(false);
      }
    } else {
      setErrors(formErrors); // Si hay errores, actualizarlos en el estado
    }
  };

  // ----------------------------------------------------------------
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    validateField(name, value);
  };

  const validateField = (name, value) => {
    let error = "";
    switch (name) {
      case "nombreCompleto":
        error = value.trim() === "" ? "El nombre completo es requerido" : "";
        break;
      case "fechaNacimiento":
        error =
          value.trim() === "" ? "La fecha de nacimiento es requerida" : "";
        break;
      case "edad":
        error = value <= 0 ? "La edad debe ser mayor que 0" : "";
        break;
      case "numeroCelular":
        error = !/^\d{10}$/.test(value)
          ? "El número de celular debe tener 10 dígitos"
          : "";
        break;
      case "domicilio":
        error = value.trim() === "" ? "El domicilio es requerido" : "";
        break;
      case "instagram":
        error = value.trim() === "" ? "Puedes escribir que no tienes" : "";
        break;
      case "facebook":
        error = value.trim() === "" ? "Puedes escribir que no tienes" : "";
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const formErrors = Object.keys(formData).forEach((acc, key) => {
  //     validateField(key, formData[key]);

  //     if (errors[key]) acc[key] = errors[key];
  //     return acc;
  //   }, {});

  //   if (Object.keys(formErrors).length === 0) {
  //     setIsSubmitting(true);
  //     // Simulating API call
  //     await new Promise((resolve) => setTimeout(resolve, 2000));
  //     setIsSubmitting(false);
  //     setIsSuccess(true);
  //   } else {
  //     setErrors(formErrors);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Crear un objeto para recolectar los errores
    const formErrors = {};

    // Validar cada campo y recolectar errores
    Object.keys(formData).forEach((key) => {
      const value = formData[key];
      let error = "";
      switch (key) {
        case "nombreCompleto":
          error = value.trim() === "" ? "El nombre completo es requerido" : "";
          break;
        case "fechaNacimiento":
          error =
            value.trim() === "" ? "La fecha de nacimiento es requerida" : "";
          break;
        case "edad":
          error = value <= 0 ? "La edad debe ser mayor que 0" : "";
          break;
        case "numeroCelular":
          error = !/^\d{10}$/.test(value)
            ? "El número de celular debe tener 10 dígitos"
            : "";
          break;
        case "domicilio":
          error = value.trim() === "" ? "El domicilio es requerido" : "";
          break;
        case "instagram":
          error = value.trim() === "" ? "Puedes escribir que no tienes" : "";
          break;
        case "facebook":
          error = value.trim() === "" ? "Puedes escribir que no tienes" : "";
          break;
        default:
          break;
      }
      // Si hay un error, agregarlo al objeto de errores
      if (error) {
        formErrors[key] = error;
      }
    });

    // Si no hay errores, avanzar al siguiente formulario
    if (Object.keys(formErrors).length === 0) {
      handleNextClick(); // Si no hay errores, ir al siguiente formulario
    } else {
      setErrors(formErrors); // Si hay errores, actualizarlos en el estado
    }
  };

  return (
    <>
      {isFirstFormVisible ? (
        <div className="min-h-screen bg-gradient-to-br from-blue-100 to-indigo-200 flex items-center justify-center p-4 md:p-8">
          <div className="bg-white rounded-xl shadow-2xl overflow-hidden max-w-5xl w-full flex flex-col md:flex-row">
            <div className="md:w-1/2 relative overflow-hidden">
              <img
                src={img1}
                alt="Customer Service"
                className="object-cover w-full h-full"
              />
              <div className="absolute inset-0 bg-gradient-to-r from-blue-500/70 to-indigo-600/70 flex items-center justify-center">
                <h1 className="text-4xl md:text-5xl font-bold text-white text-center px-4">
                  Te estamos esperando para JN13
                </h1>
              </div>
            </div>
            <div className="md:w-1/2 p-8">
              <h2 className="text-3xl font-semibold text-gray-800 mb-6">
                Llena el Formulario
              </h2>
              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label
                    htmlFor="nombreCompleto"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Nombre Completo
                  </label>
                  <div className="relative">
                    <FaUser className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    <input
                      type="text"
                      id="nombreCompleto"
                      name="nombreCompleto"
                      value={formData.nombreCompleto}
                      onChange={handleChange}
                      className={`pl-10 w-full px-4 py-2 border ${
                        errors.nombreCompleto
                          ? "border-red-500"
                          : "border-gray-300"
                      } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
                      placeholder="John Doe"
                      aria-label="nombre"
                      aria-invalid={errors.nombreCompleto ? "true" : "false"}
                    />
                  </div>
                  {errors.nombreCompleto && (
                    <p className="mt-1 text-sm text-red-500">
                      {errors.nombreCompleto}
                    </p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="fechaNacimiento"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Fecha de Nacimiento
                  </label>
                  <div className="relative">
                    <FaBirthdayCake className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    <input
                      type="date"
                      id="fechaNacimiento"
                      name="fechaNacimiento"
                      value={formData.fechaNacimiento}
                      onChange={handleChange}
                      className={`pl-10 w-full px-4 py-2 border ${
                        errors.fechaNacimiento
                          ? "border-red-500"
                          : "border-gray-300"
                      } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
                      placeholder=""
                      aria-label="fechaNacimiento"
                      aria-invalid={errors.fechaNacimiento ? "true" : "false"}
                    />
                  </div>
                  {errors.fechaNacimiento && (
                    <p className="mt-1 text-sm text-red-500">
                      {errors.fechaNacimiento}
                    </p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="edad"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Edad
                  </label>
                  <div className="relative">
                    <FaChild className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    <input
                      type="number"
                      id="edad"
                      name="edad"
                      value={formData.edades}
                      onChange={handleChange}
                      className={`pl-10 w-full px-4 py-2 border ${
                        errors.edad ? "border-red-500" : "border-gray-300"
                      } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
                      placeholder="15, 16, 17 ..."
                      aria-label="edad"
                      aria-invalid={errors.edad ? "true" : "false"}
                    />
                  </div>
                  {errors.edad && (
                    <p className="mt-1 text-sm text-red-500">{errors.edad}</p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="numeroCelular"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Numero Celular
                  </label>
                  <div className="relative">
                    <FaPhone className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    <input
                      type="tel"
                      id="numeroCelular"
                      name="numeroCelular"
                      value={formData.numeroCelular}
                      onChange={handleChange}
                      className={`pl-10 w-full px-4 py-2 border ${
                        errors.numeroCelular
                          ? "border-red-500"
                          : "border-gray-300"
                      } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
                      placeholder="1234567890"
                      aria-label="NumeroCelular"
                      aria-invalid={errors.numeroCelular ? "true" : "false"}
                    />
                  </div>
                  {errors.numeroCelular && (
                    <p className="mt-1 text-sm text-red-500">
                      {errors.numeroCelular}
                    </p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="facebook"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Facebook
                  </label>
                  <div className="relative">
                    <FaFacebook className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    <input
                      type="text"
                      id="facebook"
                      name="facebook"
                      value={formData.facebook}
                      onChange={handleChange}
                      className={`pl-10 w-full px-4 py-2 border ${
                        errors.facebook ? "border-red-500" : "border-gray-300"
                      } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
                      placeholder="Como te encuentras en Facebook"
                      aria-label="facebook"
                      aria-invalid={errors.facebook ? "true" : "false"}
                    />
                  </div>
                  {errors.facebook && (
                    <p className="mt-1 text-sm text-red-500">
                      {errors.facebook}
                    </p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="instagram"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Instagram
                  </label>
                  <div className="relative">
                    <FaInstagramSquare className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    <input
                      type="text"
                      id="instagram"
                      name="instagram"
                      value={formData.instagram}
                      onChange={handleChange}
                      className={`pl-10 w-full px-4 py-2 border ${
                        errors.instagram ? "border-red-500" : "border-gray-300"
                      } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
                      placeholder="juventud.nueva"
                      aria-label="Phone Number"
                      aria-invalid={errors.instagram ? "true" : "false"}
                    />
                  </div>
                  {errors.instagram && (
                    <p className="mt-1 text-sm text-red-500">
                      {errors.instagram}
                    </p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="domicilio"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Domicilio
                  </label>
                  <div className="relative">
                    <FaMapMarkerAlt className="absolute left-3 top-3 text-gray-400" />
                    <textarea
                      id="domicilio"
                      name="domicilio"
                      value={formData.domicilio}
                      onChange={handleChange}
                      rows="3"
                      className={`pl-10 w-full px-4 py-2 border ${
                        errors.instagram ? "border-red-500" : "border-gray-300"
                      } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
                      placeholder="Escribe tu dirección"
                      aria-label="domicilio"
                      aria-invalid={errors.domicilio ? "true" : "false"}
                    ></textarea>
                  </div>
                  {errors.domicilio && (
                    <p className="mt-1 text-sm text-red-500">
                      {errors.domicilio}
                    </p>
                  )}
                </div>
                <motion.button
                  type="submit"
                  className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors duration-300"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <span className="flex items-center justify-center">
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      Submitting...
                    </span>
                  ) : (
                    "Siguiente"
                  )}
                </motion.button>
              </form>
              {isSuccess && (
                <motion.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="mt-4 p-4 bg-green-100 text-green-700 rounded-md"
                >
                  Thank you for your submission! We'll get back to you soon.
                </motion.div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="min-h-screen bg-gradient-to-br from-blue-100 to-indigo-200 flex items-center justify-center p-4 md:p-8">
          <div className="bg-white rounded-xl shadow-2xl overflow-hidden max-w-5xl w-full flex flex-col md:flex-row">
            <div className="md:w-1/2 p-8">
              <form onSubmit={handleSubmit2} className="space-y-6">
                <div>
                  <label
                    htmlFor="nombreTutor"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Nombre del Tutor
                  </label>
                  <div className="relative">
                    <FaUser className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    <input
                      type="text"
                      id="nombreTutor"
                      name="nombreTutor"
                      value={formData2.nombreTutor}
                      onChange={handleChange2}
                      className={`pl-10 w-full px-4 py-2 border ${
                        errors.nombreTutor
                          ? "border-red-500"
                          : "border-gray-300"
                      } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
                      placeholder="Agustin Jackson"
                      aria-label="nombreTutor"
                      aria-invalid={errors.nombreTutor ? "true" : "false"}
                    />
                  </div>
                  {errors.nombreTutor && (
                    <p className="mt-1 text-sm text-red-500">
                      {errors.nombreTutor}
                    </p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="numeroTutor"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Numero Celular del Tutor
                  </label>
                  <div className="relative">
                    <FaPhone className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    <input
                      type="number"
                      id="numeroTutor"
                      name="numeroTutor"
                      value={formData2.numeroTutor}
                      onChange={handleChange2}
                      className={`pl-10 w-full px-4 py-2 border ${
                        errors.numeroTutor
                          ? "border-red-500"
                          : "border-gray-300"
                      } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
                      placeholder="9981112233"
                      aria-label="numeroTutor"
                      aria-invalid={errors.numeroTutor ? "true" : "false"}
                    />
                  </div>
                  {errors.numeroTutor && (
                    <p className="mt-1 text-sm text-red-500">
                      {errors.numeroTutor}
                    </p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="parentesco"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Parentesco
                  </label>
                  <div className="relative">
                    <FaUser className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    <input
                      type="text"
                      id="parentesco"
                      name="parentesco"
                      value={formData2.parentesco}
                      onChange={handleChange2}
                      className={`pl-10 w-full px-4 py-2 border ${
                        errors.parentesco ? "border-red-500" : "border-gray-300"
                      } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
                      placeholder="Padre, Madre, Hermano, etc..."
                      aria-label="parentesco"
                      aria-invalid={errors.parentesco ? "true" : "false"}
                    />
                  </div>
                  {errors.parentesco && (
                    <p className="mt-1 text-sm text-red-500">
                      {errors.parentesco}
                    </p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="alergias"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Alergias
                  </label>
                  <div className="relative">
                    <FaWind className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    <input
                      type="text"
                      id="alergias"
                      name="alergias"
                      value={formData2.alergias}
                      onChange={handleChange2}
                      className={`pl-10 w-full px-4 py-2 border ${
                        errors.alergias ? "border-red-500" : "border-gray-300"
                      } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
                      placeholder="Escribe cuales si tienes"
                      aria-label="alergias"
                      aria-invalid={errors.alergias ? "true" : "false"}
                    />
                  </div>
                  {errors.alergias && (
                    <p className="mt-1 text-sm text-red-500">
                      {errors.alergias}
                    </p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="medicinas"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Medicinas
                  </label>
                  <div className="relative">
                    <FaCapsules className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    <input
                      type="text"
                      id="medicinas"
                      name="medicinas"
                      value={formData2.medicinas}
                      onChange={handleChange2}
                      className={`pl-10 w-full px-4 py-2 border ${
                        errors.medicinas ? "border-red-500" : "border-gray-300"
                      } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
                      placeholder="Anota las medicinas que tengas"
                      aria-label="medicinas"
                      aria-invalid={errors.medicinas ? "true" : "false"}
                    />
                  </div>
                  {errors.medicinas && (
                    <p className="mt-1 text-sm text-red-500">
                      {errors.medicinas}
                    </p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="enfermedadCronica"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Enfermedad Crónica
                  </label>
                  <div className="relative">
                    <FaUserMd className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    <input
                      type="text"
                      id="enfermedadCronica"
                      name="enfermedadCronica"
                      value={formData2.enfermedadCronica}
                      onChange={handleChange2}
                      className={`pl-10 w-full px-4 py-2 border ${
                        errors.enfermedadCronica
                          ? "border-red-500"
                          : "border-gray-300"
                      } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
                      placeholder="Solo en caso de tener"
                      aria-label="nombreTutor"
                      aria-invalid={errors.enfermedadCronica ? "true" : "false"}
                    />
                  </div>
                  {errors.enfermedadCronica && (
                    <p className="mt-1 text-sm text-red-500">
                      {errors.enfermedadCronica}
                    </p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="abono"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Abono
                  </label>
                  <div className="relative">
                    <FaLaughBeam className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    <input
                      type="text"
                      id="abono"
                      name="abono"
                      value={formData2.abono}
                      onChange={handleChange2}
                      className={`pl-10 w-full px-4 py-2 border ${
                        errors.abono ? "border-red-500" : "border-gray-300"
                      } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
                      placeholder="descripcion del abono"
                      aria-label="nombreTutor"
                      aria-invalid={errors.abono ? "true" : "false"}
                    />
                  </div>
                  {errors.abono && (
                    <p className="mt-1 text-sm text-red-500">{errors.abono}</p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="nota"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Nota Extra
                  </label>
                  <div className="relative">
                    <FaRegCommentAlt className="absolute left-3 top-3 text-gray-400" />
                    <textarea
                      id="nota"
                      name="nota"
                      value={formData2.nota}
                      onChange={handleChange2}
                      rows="3"
                      className={`pl-10 w-full px-4 py-2 border ${
                        errors.nota ? "border-red-500" : "border-gray-300"
                      } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
                      placeholder="Deja alguna nota importante"
                      aria-label="Nota"
                      aria-invalid={errors.nota ? "true" : "false"}
                    ></textarea>
                  </div>
                </div>
                <motion.button
                  type="submit"
                  className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors duration-300"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <span className="flex items-center justify-center">
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      Submitting...
                    </span>
                  ) : (
                    "REGISTRARME"
                  )}
                </motion.button>
              </form>
              {isSuccess && (
                <motion.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="mt-4 p-4 bg-green-100 text-green-700 rounded-md"
                >
                  Gracias por registrate!!!
                </motion.div>
              )}
            </div>
            <div className="md:w-1/2 relative overflow-hidden">
              <img
                src={img2}
                alt="Customer Service"
                className="object-cover w-full h-full"
              />
              <div className="absolute inset-0 bg-gradient-to-r from-blue-500/70 to-indigo-600/70 flex items-center justify-center">
                <h1 className="text-4xl md:text-5xl font-bold text-white text-center px-4">
                  No te lo puedes perder!
                </h1>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default App;
